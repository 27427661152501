import React, { Component } from 'react'
import {
  SolutionOutlined,
  TeamOutlined,
  BankOutlined
} from '@ant-design/icons';
import { Layout, Menu, Card, Input, Divider, Radio, Checkbox, message, Form } from 'antd';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

const { Header, Sider, Content } = Layout;
const layoutStyle = {
  overflow: 'hidden',
};
const headerStyle = {
  color: '#fff',
  padding: '0 20px'
};
const contentStyle = {
  minHeight: 120,
  color: '#fff',
  padding: 16
};
const siderStyle = {
  color: '#fff',
};
const items = [
  {
    key: '1',
    icon: <SolutionOutlined />,
    label: <NavLink to="/home">首页</NavLink>,
  },
  {
    key: '2',
    icon: <SolutionOutlined />,
    label: <NavLink to="/list">调查列表</NavLink>,
  },
  {
    key: '3',
    icon: <SolutionOutlined />,
    label: <NavLink to="/answer">参与答题</NavLink>,
  },
];

export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      detailsId: '',
      questions: []
    };
  }
  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    const detailsId = searchParams.get('id');
    this.setState({
      detailsId
    }, () =>
      this.fetchData()
    )
  }
  fetchData = () => {
    axios.get(`/api/backend/survey/details/${this.state.detailsId}`)
      .then(response => {
        const data = response.data.data
        data.questions?.map(v => {
          const { question_type, options } = v;
          if (question_type == '单选') {
            options.forEach(option => {
              if (option.status === 1) {
                v.answer_text = option.option_text
              }
              if (option.status === 1 && option.option_text == '其他') {
                v.answer_text1 = option.answer_text
              }
            });
          }
          if (question_type == '多选') {
            const answer_text = []
            options.forEach(option => {
              if (option.status === 1) {
                answer_text.push(option.option_text)
              }
              if (option.status === 1 && option.option_text == '其他') {
                v.answer_text1 = option.answer_text
              }
            });
            v.answer_text = answer_text
          }
        })
        this.setState({
          questions: data.questions
        })
      })
      .catch(error => console.error(error));
  };
  pdf = () => {
    let param = {
      ids: [this.state.detailsId],
    };
    let downUrl = `/api/backend/survey/person/pdf`;
    axios({
      method: "post",
      url: downUrl,
      data: param,
      responseType: "blob",
    }).then((res) => {
      const content = res.data;
      const blob = new Blob([content]);
      const fileName = '导出.zip'
      const selfURL = window[window.webkitURL ? "webkitURL" : "URL"];
      let elink = document.createElement("a");
      if ("download" in elink) {
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = selfURL["createObjectURL"](blob);
        document.body.appendChild(elink);
        elink.click();
        selfURL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      } else {
        navigator.msSaveBlob(blob, fileName);
      }
    });
  };

  render() {
    const { questions } = this.state;
    return (
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>
          <span>决策后评估问卷调查</span>
        </Header>
        <Layout>
          <Sider width="226" style={siderStyle}>
            <Menu
              defaultSelectedKeys={['2']}
              defaultOpenKeys={['sub1']}
              theme="dark"
              items={items}
            />
          </Sider>
          <Content style={contentStyle}>
            <Card>
              <div ><BankOutlined style={{ marginRight: 4 }} /><span style={{ color: '#333' }}>首页 / 调查列表 / 问卷详情</span></div>
              <Divider style={{ margin: '10px 0 20px 0' }} />
              <div>
                <a onClick={this.pdf} style={{ float: 'right' }}>导出</a>
                <NavLink style={{ display: 'inline', marginRight: 10, float: 'right' }} to="/list">返回列表</NavLink>
              </div>
              {questions?.map((v, index) => (
                <div key={index} style={{ marginBottom: 20 }}>
                  {index <= 2 ? <span>{v.question_text}</span> : <div>{v.question_text}</div>}
                  {v.question_type === '多选' ? (
                    <>
                      <Checkbox.Group style={{ flexDirection: 'column' }} value={v.answer_text} key={`${index}-checkbox`} disabled>
                        {v.options?.map(i => (
                          <Checkbox key={i.option_id} value={i.option_text} style={{ margin: '10px 0 0 0' }}><span style={{ color: 'rgba(0, 0, 0, 0.88)' }}>{i.option_text}</span></Checkbox>
                        ))}
                      </Checkbox.Group>
                      {v.answer_text?.includes("其他") && <div style={{ marginTop: 10 }}>{v.answer_text1}</div>}
                    </>
                  ) : index <= 2 ? (
                    <span style={{ marginRight: 20, display: 'inline-block' }}>{v.options[0].answer_text}</span>
                  ) : v.question_type === '文本' ? (
                    <div>{v.options[0].answer_text}</div>
                  ) : (
                    <>
                      <Radio.Group value={v.answer_text} key={`${index}-radio`} disabled>
                        {v.options?.map(i => (
                          <Radio
                            value={i.option_text}
                            style={{ display: 'flex', margin: '10px 0 0 0', color: 'rgba(0, 0, 0, 0.88)' }}
                            key={i.option_id}
                          >
                            {i.option_text}
                          </Radio>
                        ))}
                      </Radio.Group>
                      {v.answer_text == "其他" && <div style={{ marginTop: 10 }}>{v.answer_text1}</div>}
                    </>
                  )}
                </div>
              ))}
            </Card>
          </Content>
        </Layout>
      </Layout>
    )
  }
}
