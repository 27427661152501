import React, { Component } from 'react'
import endIcon from "../assets/end_icon.png";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }
  componentDidMount() {
  }

  render() {
    return (
      <div style={{ padding: '24px 16px', textAlign: 'center' }}>
        <img src={endIcon} width={160} height={129} style={{ marginTop: 40 }} />
        <div style={{ marginTop: 20, fontSize: 18, color: '#333333', fontWeight: '600' }}>提交成功</div>
        <div style={{ marginTop: 8, fontSize: 14, color: '#999999' }}>非常感谢您的宝贵意见</div>
      </div>
    )
  }
}
