import WithRouterDemo from './WithRouterDemo';
// import AntdDemo from "./AntdDemo";

function App({ root }) {
  return (
    <div className="App">
      <WithRouterDemo />
    </div>
  );
}

export default App;
