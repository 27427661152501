import React, { Component } from 'react'
import {
  SolutionOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { Layout, Checkbox, Menu, Card, Input, Radio, Button, message } from 'antd';
// import './index.css';

const { Header, Sider, Content } = Layout;
const layoutStyle = {
  overflow: 'hidden',
  height: '100vh'
};
const headerStyle = {
  color: '#fff',
  padding: '0 20px'
};
const contentStyle = {
  minHeight: 120,
  color: '#000000e0',
  padding: 16,
  overflow: 'auto'
};
const siderStyle = {
  color: '#fff',
};
const items = [
  {
    key: '1',
    icon: <SolutionOutlined />,
    label: <NavLink to="/home">首页</NavLink>,
  },
  {
    key: '2',
    icon: <SolutionOutlined />,
    label: <NavLink to="/list">调查列表</NavLink>,
  },
  {
    key: '3',
    icon: <SolutionOutlined />,
    label: <NavLink to="/answer">参与答题</NavLink>,
  },
];
export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      loading: false,
      phone: '',
      nickName: ''
    };
  }
  componentDidMount() {
    this.fetchData()
  }
  fetchData = () => {
    axios.get('/api/backend/survey')
      .then(response => {
        const data = response.data.data
        data.questions[0].answer_text = '出租车（含网约车）驾驶员'
        this.setState({
          questions: data.questions
        })
      })
      .catch(error => console.error(error));
  };
  handleRadioChange = (e, questionIndex, type) => {
    const { questions } = this.state
    const updatedData = [...questions];
    if (type === 'answer_text1') {
      updatedData[questionIndex].answer_text1 = e.target.value;
    } else {
      updatedData[questionIndex].answer_text = type === 'checkbox' ? e : e.target.value;
    }
    this.setState({ questions: updatedData });
  };

  onFinish = () => {
    this.setState({ loading: true })
    const { questions, nickname, phone } = this.state
    if (nickname == '' || nickname.trim() === "") {
      this.setState({ loading: false })
      return message.warning('请填写所有问题');
    }
    if (phone == '' || phone.trim() === "") {
      this.setState({ loading: false })
      return message.warning('请填写所有问题');
    }
    if (!questions.slice(0, -1).every(q => q.answer_text)) {
      this.setState({ loading: false })
      return message.warning('请填写所有问题');
    }
    if (questions.slice(0, -1).some(q => q.question_type === '单选' && q.answer_text == "其他" && (!q.answer_text1 || q.answer_text1.trim() === ""))) {
      this.setState({ loading: false })
      return message.warning('请填写所有问题');
    }
    if (questions.slice(0, -1).some(q => q.question_type === '多选' && q.answer_text.length === 0)) {
      this.setState({ loading: false })
      return message.warning('请填写所有问题');
    }
    if (questions.some(q => q.question_type === '多选' && q.answer_text.includes("其他") && (!q.answer_text1 || q.answer_text1.trim() === ""))) {
      this.setState({ loading: false })
      return message.warning('请填写所有问题');
    }
    questions.forEach((v) => {
      const { question_type, options, answer_text, answer_text1 } = v;
      switch (question_type) {
        case '文本':
          options[0].answer_text = answer_text;
          options[0].status = 1;
          break;
        case '单选':
          options.forEach(option => {
            if (option.option_text === answer_text) {
              option.status = 1;
            }
            if (option.option_text === '其他') {
              option.answer_text = option.option_text === answer_text ? answer_text1 : null;
            }
          });
          break;
        case '多选':
          options.forEach(option => {
            if (answer_text.includes(option.option_text)) {
              option.status = 1;
            }
            if (option.option_text === "其他") {
              option.answer_text = answer_text.includes(option.option_text) ? answer_text1 : null;
            }
          });
          break;
      }
    });
    axios.post('/api/backend/survey/submit', { nickname: this.state.nickname, phone: this.state.phone, questions: questions, type: '网页' })
      .then(response => {
        if (response.data.status == 0) {
          this.props.history.push('/list');
        } else {
          message.warning(response.data.message);
          this.setState({ loading: false })
        }
      })
      .catch(error => console.error(error));
  };

  render() {
    const { loading, questions, nickname, phone } = this.state;
    return (
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>
          <span>决策后评估问卷调查</span>
        </Header>
        <Layout>
          <Sider width="226" style={siderStyle}>
            <Menu
              defaultSelectedKeys={['3']}
              defaultOpenKeys={['sub1']}
              theme="dark"
              items={items}
            />
          </Sider>
          <Content style={contentStyle}>
            <Card style={{ background: '#FFFFFF', boxShadow: '0 2px 4px 0 #00000033', borderRadius: 12, marginBottom: 8 }}>
              <div style={{ fontSize: 16, fontWeight: 600 }}>姓名：</div>
              <Input value={nickname} onChange={e => this.setState({ nickname: e.target.value })} />
              <div style={{ fontSize: 16, fontWeight: 600, marginTop: 4 }}>手机号：</div>
              <Input value={phone} onChange={e => this.setState({ phone: e.target.value })} />
            </Card>
            {questions?.map((v, index) => (
              <Card style={{ background: '#FFFFFF', boxShadow: '0 2px 4px 0 #00000033', borderRadius: 12, marginBottom: 8 }} key={v.question_id || index}>
                <div key={index}>
                  <div style={{ fontSize: 16, fontWeight: 600 }}>{v.question_text}</div>
                  {v.question_type === '多选' ? (
                    <>
                      <Checkbox.Group style={{ flexDirection: 'column' }} value={v.answer_text} key={`${index}-checkbox`} onChange={e => this.handleRadioChange(e, index, 'checkbox')}>
                        {v.options?.map(i => (
                          <Checkbox key={i.option_id} value={i.option_text} style={{ margin: '10px 0 0 0' }}>{i.option_text}</Checkbox>
                        ))}
                      </Checkbox.Group>
                      {v.answer_text?.includes("其他") && <Input key={`${index}-checkbox-Input`} value={v.answer_text1} onChange={e => this.handleRadioChange(e, index, 'answer_text1')} />}
                    </>
                  ) : v.question_type === '文本' ? (
                    <Input.TextArea value={v.answer_text} onChange={e => this.handleRadioChange(e, index)} />
                  ) : (
                    <>
                      <Radio.Group value={v.answer_text} key={`${index}-radio`} onChange={e => this.handleRadioChange(e, index)}>
                        {v.options?.map(i => (
                          <Radio
                            value={i.option_text}
                            style={{ display: 'flex', margin: '10px 0 0 0' }}
                            key={i.option_id}
                          >
                            {i.option_text}
                          </Radio>
                        ))}
                      </Radio.Group>
                      {v.answer_text == "其他" && <Input key={`${index}-radio-Input`} value={v.answer_text1} onChange={e => this.handleRadioChange(e, index, 'answer_text1')} />}
                    </>
                  )}
                </div>
              </Card>
            ))}
            <Button loading={loading} type="primary" onClick={this.onFinish} style={{ width: '100%' }}>
              确认提交
            </Button>
          </Content>
        </Layout>
      </Layout >
    )
  }
}
