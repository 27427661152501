import React, { Component } from 'react'
import {
  SolutionOutlined,
  BankOutlined
} from '@ant-design/icons';
import { Layout, Menu, Card, Space, Table, Divider, Form, Input, Button, Select, DatePicker } from 'antd';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

const { Header, Sider, Content } = Layout;
const { RangePicker } = DatePicker;
const layoutStyle = {
  overflow: 'hidden',
  height: '100vh'
};
const headerStyle = {
  color: '#fff',
  padding: '0 20px'
};
const contentStyle = {
  minHeight: 120,
  color: '#fff',
  padding: 16,
  overflowY: 'auto'
};
const siderStyle = {
  color: '#fff',
};
const items = [
  {
    key: '1',
    icon: <SolutionOutlined />,
    label: <NavLink to="/home">首页</NavLink>,
  },
  {
    key: '2',
    icon: <SolutionOutlined />,
    label: <NavLink to="/list">调查列表</NavLink>,
  },
  {
    key: '3',
    icon: <SolutionOutlined />,
    label: <NavLink to="/answer">参与答题</NavLink>,
  },
];
export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      identityList: [],
      query: {
        fill_time_start: undefined,
        fill_time_end: undefined,
        name: undefined,
        identity: undefined
      },
      current: 1,
      size: 10,
      selectedRowKeys: [],
      loading: false
    };
  }
  componentDidMount() {
    axios.get('/api/backend/person/identity/down')
      .then(response => {
        this.setState({
          identityList: response.data.data
        })
      })
      .catch(error => console.error(error));
    this.fetchData()
  }
  fetchData = () => {
    const { query, current, size } = this.state;
    const params = {
      ...query,
      current,
      size
    }
    axios.get('/api/backend/survey/questionnaire/list', { params })
      .then(response => {
        const data = response.data.data
        this.setState({
          data: data.rows,
          current: data.number,
          total: data.total,
          loading: false
        })
      })
      .catch(error => console.error(error));
  };
  pdf = (record) => {
    let param = {
      ids: record,
    };
    let downUrl = `/api/backend/survey/person/pdf`;
    this.setState({ loading: true })
    axios({
      method: "post",
      url: downUrl,
      data: param,
      responseType: "blob",
    }).then((res) => {
      const content = res.data;
      const blob = new Blob([content]);
      const fileName = '导出.zip'
      const selfURL = window[window.webkitURL ? "webkitURL" : "URL"];
      let elink = document.createElement("a");
      if ("download" in elink) {
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = selfURL["createObjectURL"](blob);
        document.body.appendChild(elink);
        elink.click();
        selfURL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
        this.setState({ loading: false })
      } else {
        navigator.msSaveBlob(blob, fileName);
      }
    });
  };
  changeTable = (e) => {
    const { current, pageSize } = e;
    this.setState({ current: current, size: pageSize }, () => this.fetchData());
  };
  onSelectChange = (selected, list) => {
    this.setState({ selectedRowKeys: selected });
  };
  render() {
    const { data, current, total, selectedRowKeys, loading } = this.state;
    const columns = [
      {
        title: '序号',
        key: 'index',
        render: (text, _, index) => <>{index + 1}</>,
      },
      {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '手机号',
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: '联系地址',
        dataIndex: 'contact_address',
        key: 'contact_address',
      },
      {
        title: '身份',
        dataIndex: 'identity',
        key: 'identity',
      },
      {
        title: '填写时间',
        dataIndex: 'create_time',
        key: 'create_time',
        render: (record) => (
          <span>{moment(record).format("YYYY-MM-DD HH:mm:ss")}</span>
        )
      },
      {
        title: '操作',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <NavLink className="nav-link" to={`/details?id=${record.id}`}>查看</NavLink>
            <a onClick={() => this.pdf([record.id])}>导出</a>
          </Space>
        ),
      },
    ];
    const paginationProps = {
      total: total,
      current: current,
      showTotal: () => "共" + total + "条",
    };
    const rowSelection = { selectedRowKeys, onChange: this.onSelectChange };
    return (
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>
          <span>决策后评估问卷调查</span>
        </Header>
        <Layout>
          <Sider width="226" style={siderStyle}>
            <Menu
              defaultSelectedKeys={['2']}
              defaultOpenKeys={['sub1']}
              theme="dark"
              items={items}
            />
          </Sider>
          <Content style={contentStyle}>
            <Card>
              <div ><BankOutlined style={{ marginRight: 4 }} /> 首页 {'>'} <span style={{ color: '#333' }}>调查列表</span></div>
              <Divider style={{ margin: '10px 0 20px 0' }} />
              <Form
                layout='inline'
                onFinish={(value) => {
                  const { fill_time } = value;
                  if (fill_time && fill_time.length) {
                    value.fill_time_start = fill_time[0].format('YYYY-MM-DD');
                    value.fill_time_end = fill_time[1].format('YYYY-MM-DD');
                    delete value.fill_time;
                  }
                  this.setState({
                    query: { ...value },
                    current: 1,
                    loading: true
                  }, () => {
                    this.fetchData()
                  })
                }}
                onReset={() => {
                  this.setState({
                    query: {
                      fill_time_start: undefined,
                      fill_time_end: undefined,
                      name: undefined,
                      identity: undefined
                    },

                  }, () => {
                    this.fetchData()
                  })
                }}
              >
                <Form.Item name={'name'} label="姓名">
                  <Input placeholder="请输入" style={{ width: 200 }} />
                </Form.Item>
                <Form.Item name={'phone'} label="手机号">
                  <Input placeholder="请输入" style={{ width: 200 }} />
                </Form.Item>
                <Form.Item name={'identity'} label="身份" >
                  <Select
                    style={{ width: 200 }}
                    options={this.state.identityList}
                    placeholder="请选择"
                  />
                </Form.Item>
                <Form.Item name={'fill_time'} label="填写时间">
                  <RangePicker
                    placement={'topRight'}
                    style={{ width: 240 }}
                    placeholder={["开始月份", "结束月份"]}
                  />
                </Form.Item>
                <Form.Item name={'type'} label="来源" >
                  <Select
                    style={{ width: 180 }}
                    options={[
                      { value: '网页', label: '网页' },
                      { value: '小程序', label: '小程序' },
                    ]}
                    placeholder="请选择"
                  />
                </Form.Item>
                <div style={{ marginLeft: 'auto' }}>
                  <Button type="primary" ghost style={{ marginRight: 20 }} htmlType="reset">
                    重置
                  </Button>
                  <Button type="primary" htmlType="submit" style={{ marginRight: 20 }}>
                    查询
                  </Button>
                  <Button type="primary" onClick={() => { this.pdf(rowSelection.selectedRowKeys) }} disabled={rowSelection.selectedRowKeys.length === 0 ? true : false} >
                    导出
                  </Button>
                </div>
              </Form>
              <Table
                rowKey="id"
                columns={columns}
                dataSource={data}
                pagination={paginationProps}
                onChange={this.changeTable}
                rowSelection={rowSelection}
                loading={loading}
              />
            </Card>
          </Content>
        </Layout>
      </Layout >
    )
  }
}
