import React, { Component } from 'react'
import {
  SolutionOutlined,
  BankOutlined
} from '@ant-design/icons';
import { Layout, Menu, Card, Divider } from 'antd';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

const { Header, Sider, Content } = Layout;
const layoutStyle = {
  overflow: 'hidden',
  height: '100vh'
};
const headerStyle = {
  color: '#fff',
  padding: '0 20px'
};
const contentStyle = {
  minHeight: 120,
  color: '#fff',
  padding: 16
};
const siderStyle = {
  color: '#fff',
};
const items = [
  {
    key: '1',
    icon: <SolutionOutlined />,
    label: <NavLink to="/home">首页</NavLink>,
  },
  {
    key: '2',
    icon: <SolutionOutlined />,
    label: <NavLink to="/list">调查列表</NavLink>,
  },
  {
    key: '3',
    icon: <SolutionOutlined />,
    label: <NavLink to="/answer">参与答题</NavLink>,
  },
];
export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }
  componentDidMount() {
    axios.get('/api/backend/survey/statistics')
      .then(response => {
        const data = response.data.data
        this.setState({
          data
        })
      })
      .catch(error => console.error(error));
  }

  render() {
    const { data } = this.state;
    return (
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>
          <span>决策后评估问卷调查</span>
        </Header>
        <Layout>
          <Sider width="226" style={siderStyle}>
            <Menu
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub1']}
              theme="dark"
              items={items}
            />
          </Sider>
          <Content style={contentStyle}>
            <Card>
              <div ><BankOutlined style={{ marginRight: 4 }} /><span style={{ color: '#333' }}>首页</span></div>
              <Divider style={{ margin: '10px 0 20px 0' }} />
              <div style={{ marginBottom: 16 }}>已填写：</div>
              <div style={{ fontWeight: 600 }}>
                <span style={{ marginRight: 60 }}>乘客<span style={{ margin: '0 4px' }}>{data.passenger || '0'}</span>人</span>
                <span style={{ marginRight: 60 }}>出租车经营者（巡游车企业、网约车平台等）<span style={{ margin: '0 4px' }}>{data.taxi_operator || '0'}</span>人</span>
                <span style={{ marginRight: 60 }}>出租车（含网约车）驾驶员<span style={{ margin: '0 4px' }}>{data.taxi_driver || '0'}</span>人</span>
                <span style={{ marginRight: 60 }}>政府部门工作人员<span style={{ margin: '0 4px' }}>{data.government_staff || '0'}</span>人</span>
                <span>其他<span style={{ margin: '0 4px' }}>{data.other || '0'}</span>人</span>
              </div>
            </Card>
          </Content>
        </Layout>
      </Layout >
    )
  }
}
