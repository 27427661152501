import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from './Home';
import HomeDetails from './Home/homeDetails';
import HomeList from './Home/list';
import HomeAnswer from './Home/answer';
// import About from './About';
// import AboutSm from './About/indexSm';
import AboutWj from './About/indexWj';
import AboutEnd from './About/indexEnd';

import './index.css';

export default class ReactRouterParams extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Switch>
            {/* <Route path="/index" component={About} /> */}
            {/* <Route path="/indexSm" component={AboutSm} /> */}
            <Route path="/index" component={AboutWj} />
            <Route path="/indexEnd" component={AboutEnd} />

            <Route path="/home" component={Home} />
            <Route path="/details" component={HomeDetails} />
            <Route path="/list" component={HomeList} />
            <Route path="/answer" component={HomeAnswer} />
          </Switch>
        </div>
      </BrowserRouter>
    )
  }
}
