import React, { Component } from 'react'
import {
  SolutionOutlined,
} from '@ant-design/icons';
import { Layout, Checkbox, Card, Input, Radio, Button, message } from 'antd';
import axios from 'axios';
import './index.css';
import background from "../assets/background.png";
import top_icon from "../assets/top_icon.png";

const { Content } = Layout;

export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.fetchData()
  }
  fetchData = () => {
    axios.get('/api/backend/survey')
      .then(response => {
        const data = response.data.data
        this.setState({
          questions: data.questions
        })
      })
      .catch(error => console.error(error));
  };
  handleRadioChange = (e, questionIndex, type) => {
    const { questions } = this.state
    const updatedData = [...questions];
    if (type == 'answer_text1') {
      updatedData[questionIndex].answer_text1 = e.target.value;
    } else {
      updatedData[questionIndex].answer_text = type == 'checkbox' ? e : e.target.value;
    }
    this.setState({ questions: updatedData });
  };

  onFinish = () => {
    const { questions } = this.state
    if (!questions.slice(0, -1).every(q => q.answer_text)) {
      return message.warning('请填写所有问题');
    }
    if (questions.slice(0, -1).some(q => q.question_type == '单选' && q.answer_text == "其他" && !q.answer_text1)) {
      return message.warning('请填写所有问题');
    }
    if (questions.slice(0, -1).some(q => q.question_type == '多选' && q.answer_text.length === 0)) {
      return message.warning('请填写所有问题');
    }
    if (questions.some(q => q.question_type == '多选' && q.answer_text.includes("其他") && !q.answer_text1)) {
      return message.warning('请填写所有问题');
    }
    questions.forEach((v) => {
      const { question_type, options, answer_text, answer_text1 } = v;
      switch (question_type) {
        case '文本':
          options[0].answer_text = answer_text;
          options[0].status = 1;
          break;
        case '单选':
          options.forEach(option => {
            if (option.option_text === answer_text) {
              option.status = 1;
            }
            if (option.option_text == '其他') {
              option.answer_text = option.option_text === answer_text ? answer_text1 : null;
            }
          });
          break;
        case '多选':
          options.forEach(option => {
            if (answer_text.includes(option.option_text)) {
              option.status = 1;
            }
            if (option.option_text === "其他") {
              option.answer_text = answer_text.includes(option.option_text) ? answer_text1 : null;
            }
          });
          break;
      }
    });
    this.setState({ loading: true })
    axios.post('/api/backend/survey/submit', { questions: questions })
      .then(response => {
        if (response.data.status == 0) {
          this.props.history.push('/indexEnd');
        } else {
          message.warning(response.data.message);
          this.setState({ loading: false })
        }
      })
      .catch(error => console.error(error));
    this.setState({ loading: false })
  };

  render() {
    const { loading, questions } = this.state;
    return (
      <Layout style={{ overflow: 'hidden', height: '100%', backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', position: 'relative' }}>
        <img src={top_icon} style={{ width: '100%', position: 'absolute' }} />
        <Content style={{ minHeight: 120, color: '#fff', padding: '16px 12px', marginTop: '44%' }}>
          {questions?.map((v, index) => (
            <Card style={{ background: '#FFFFFF', boxShadow: '0 2px 4px 0 #00000033', borderRadius: 12, marginBottom: 8 }} key={v.question_id || index}>
              <div key={index}>
                <div style={{ fontSize: 16, fontWeight: 600 }}>{v.question_text}</div>
                {v.question_type === '多选' ? (
                  <>
                    <Checkbox.Group style={{ flexDirection: 'column' }} value={v.answer_text} key={`${index}-checkbox`} onChange={e => this.handleRadioChange(e, index, 'checkbox')}>
                      {v.options?.map(i => (
                        <Checkbox key={i.option_id} value={i.option_text} style={{ margin: '10px 0 0 0' }}>{i.option_text}</Checkbox>
                      ))}
                    </Checkbox.Group>
                    {v.answer_text?.includes("其他") && <Input key={`${index}-checkbox-Input`} value={v.answer_text1} onChange={e => this.handleRadioChange(e, index, 'answer_text1')} />}
                  </>
                ) : index <= 1 ? (
                  <Input value={v.answer_text} onChange={e => this.handleRadioChange(e, index)} />
                ) : v.question_type === '文本' ? (
                  <Input.TextArea value={v.answer_text} onChange={e => this.handleRadioChange(e, index)} />
                ) : (
                  <>
                    <Radio.Group value={v.answer_text} key={`${index}-radio`} onChange={e => this.handleRadioChange(e, index)}>
                      {v.options?.map(i => (
                        <Radio
                          value={i.option_text}
                          style={{ display: 'flex', margin: '10px 0 0 0' }}
                          key={i.option_id}
                        >
                          {i.option_text}
                        </Radio>
                      ))}
                    </Radio.Group>
                    {v.answer_text == "其他" && <Input key={`${index}-radio-Input`} value={v.answer_text1} onChange={e => this.handleRadioChange(e, index, 'answer_text1')} />}
                  </>
                )}
              </div>
            </Card>
          ))}
          <Button loading={loading} type="primary" onClick={this.onFinish} style={{ width: '100%' }}>
            确认提交
          </Button>
        </Content>
      </Layout >
    )
  }
}
